const FOOTER_DATA = [
  {
    title: 'Company',
    menuItem: [
      {
        link: '#',
        label: 'About team',
      },
      {
        link: '#',
        label: 'Affiliate center',
      },
      {
        link: '#',
        label: 'Careers & Culture',
      },
    ],
  },
  {
    title: 'About Us',
    menuItem: [
      {
        link: '#',
        label: 'Customer Support',
      },
      {
        link: '#',
        label: 'About Us',
      },
      {
        link: '#',
        label: 'Copyright',
      },
    ],
  },
  {
    title: 'Our Information',
    menuItem: [
      {
        link: '#',
        label: 'Privacy Policy',
      },
      {
        link: '#',
        label: 'Terms & Conditions',
      },
    ],
  },
  // {
  //   title: 'My Account',
  //   menuItem: [
  //     {
  //       link: '#',
  //       label: 'Press inquiries',
  //     },
  //     {
  //       link: '#',
  //       label: 'Social media',
  //     },
  //     {
  //       link: '#',
  //       label: 'directories',
  //     },
  //     {
  //       link: '#',
  //       label: 'Images & B-roll',
  //     },
  //     {
  //       link: '#',
  //       label: 'Permissions',
  //     },
  //   ],
  // },
  // {
  //   title: 'Policy',
  //   menuItem: [
  //     {
  //       link: '#',
  //       label: 'Application security',
  //     },
  //     {
  //       link: '#',
  //       label: 'Software principles',
  //     },
  //     {
  //       link: '#',
  //       label: 'Unwanted software policy',
  //     },
  //     {
  //       link: '#',
  //       label: 'Responsible supply chain',
  //     },
  //   ],
  // },
];
export default FOOTER_DATA;
